<template>
  <page-layout title="Help" :linkDefault="`/${this.username}/dashboard`">
    <img
      class="-mt-12 mx-auto flex justify-center"
      :src="require('@/assets/help-on-the-way.svg')"
      style="width: 450px !important"
    />
    <div class="-mt-10 pb-8 px-4">
        <p class="px-6 text-center text-xl font-bold pb-2">Help is on the way!</p>
        <p class="font-extralight text-center px-5 pb-8">
        Request submitted successfully. <br />
        Sit back & relax - we’ll contact you shortly!
        </p>
        <div class="flex flex-row items-center  justify-center">
        <Button class=" w-40" type="primary" text="home" @click="() => router.push(`/`)" />
        </div>
    </div>
  </page-layout>
</template>

<script >
import { useRouter } from 'vue-router';
import { mapState } from "vuex";
import PageLayout from "../components/base/pageLayout.vue";
import Button from '../components/button.vue';

export default {
  components: { PageLayout, Button },
  name: "Help",
  computed: {
    ...mapState(["username"]),
  },
  setup(){
      const router = useRouter();
      return { router };
  }
};
</script>